import Glide from '@glidejs/glide';

export default class Slider {
    constructor(containerSelector) {
        this.containerSelector = containerSelector;
        this.init();
    }

    init() {
        const sliderContainers = $(this.containerSelector);

        sliderContainers.each((index, element) => {
            const $element = $(element);
            const slidesCount = $element.find('.glide__slide').length;

            if (slidesCount > 1) {
                const glide = new Glide(element, {
                    type: 'carousel',
                    perView: 1,
                    gap: 30,
                    navigation: {
                        bullets: true,
                    },
                });

                const mountGlide = () => {
                    if (window.innerWidth < 600) {
                        glide.mount();
                    }
                };

                const destroyGlide = () => {
                    if (window.innerWidth >= 600) {
                        glide.destroy();
                    } else {
                        mountGlide();
                    }
                };

                mountGlide();

                $(window).on('resize', () => {
                    destroyGlide();
                });
            }
        });
    }
}