(function($) {

    $(document).ready(function() {
        // add sticky to header and jobs search bar on desktop only
        $(window).scroll(function() {
            if (window.innerWidth > 1200) {
                var scrollPosition = $(window).scrollTop();

                // Adjust the value below to determine when the CSS class should be added
                var scrollThreshold = 110;
                var scrollThreshold2 = 502;
                //console.log("Scroll position:", scrollPosition); // Log the scroll position in the console

                if (scrollPosition > scrollThreshold) {
                    // Add the CSS class to your desired element
                    $('.page-head').addClass('sticky');
                    $('.shift-page-content').addClass('sticky');

                } else {
                    // Remove the CSS class if the scroll position is below the threshold
                    $('.page-head').removeClass('sticky');
                    $('.shift-page-content').removeClass('sticky');

                }
                if ($(".page-content-banner .job-search__outer").length) {
                    if (scrollPosition > scrollThreshold2) {
                        // Add the CSS class to your desired element
                        $('.job-search__outer').addClass('sticky');
                        $('.shift-page-content').addClass('sticky-er');


                    } else {
                        // Remove the CSS class if the scroll position is below the threshold
                        $('.job-search__outer').removeClass('sticky');
                        $('.shift-page-content').removeClass('sticky-er');

                    }
                }
            }
        });
        

        // Hide dropdown mega menu on click outside
        $(document).on("click", function(event){
            if(!$(event.target).closest(".js-toggle-submenu__link").length && !$(event.target).closest(".mega-menu").length){
                $(".primary-nav__item--active").siblings('.primary-nav__item--active')
                .removeClass('primary-nav__item--active')
                .find('.mega-menu')
                .slideUp('fast');
    
            $(".primary-nav__item--active").toggleClass('primary-nav__item--active')
                .find('.mega-menu')
                .slideToggle('fast');
                $(".mega-menu").slideUp("fast");
                
            }
        });



        const pagers = document.querySelectorAll("a.facetwp-page");
        
        pagers.forEach((pager) => {
            pager.removeAttribute('tabindex');
            pager.setAttribute('title', pager.innerText);
        });

        const miSelects = document.querySelectorAll("select.facetwp-radius-dropdown");

        miSelects.forEach((miSelect) => {
            miSelect.setAttribute('role', 'select');
        });

        // collapsible checkbox facets on job search page
        const accordionTitles = document.querySelectorAll(".job-search__filter-title");

        accordionTitles.forEach((accordionTitle) => {
            accordionTitle.addEventListener("click", () => {
                if (accordionTitle.classList.contains("_active")) {
                    accordionTitle.classList.remove("_active");
                } else {
                    const accordionTitlesWithIsOpen = document.querySelectorAll("._active");
                    accordionTitlesWithIsOpen.forEach((accordionTitleWithIsOpen) => {
                        accordionTitleWithIsOpen.classList.remove("_active");
                    });
                    accordionTitle.classList.add("_active");
                }
            });
            $(document).on("click", function(e) {
                if ($(e.target).is(accordionTitle) === false && !$(e.target).closest('.facetwp-type-checkboxes').length && !$(e.target).closest('.material-icons').length) {
                    $(accordionTitle).removeClass("_active");
                }
            });
            $(window).scroll(function() {
                $(accordionTitle).removeClass("_active");
            });
        });
        // end
        // job search block mobile collapsible 
        var coll = document.getElementsByClassName("job-search__mobile-collapse");
        var i;

        for (i = 0; i < coll.length; i++) {
            coll[i].addEventListener("click", function() {
                var element = document.querySelector('.job-search__mobile-collapse');
                element.classList.toggle("expand");
            });
        }
        // end
        // handle facetwp search button
        $(document).on('click', '.fwp-submit', function() {
            FWP.refresh();
            var href = $(this).attr('data-href');
            var search_string = window.location.search;
            window.location.href = href + search_string;
        });
        // enable enter key search for facetwp
        $(document).keyup(function(event) {
            if (!($("input.search-input").is(":focus"))) {
                var keycode = (event.keyCode ? event.keyCode : event.which);
                if (keycode == '13' && window.location.href.indexOf("/search-jobs") <= -1) {
                    FWP.parseFacets();
                    FWP.setHash();
                    $(".fwp-submit").trigger("click");
                }
            }
        });

        // job search block mobile collapsible 
        var coll = document.getElementsByClassName("fwp-submit-results");
        var element = document.querySelector('.job-search__mobile-collapse');

        for (var i = 0; i < coll.length; i++) {
            coll[i].addEventListener("click", function() {
                if ($(window).width() < 1200) {
                    element.classList.toggle("expand");
                }
            });
        }
        // end


        // Check if #job-search-bar-block exists
        if ($('#job-search-bar-block').length > 0) {
            // If it exists, hide the .right-sidebar element
            $('.right-sidebar').css('display', 'none');
        }

        if ($('#browse-by-role-block').length > 0) {
            // If it exists, hide the .right-sidebar element
            $('.right-sidebar').css('display', 'none');
            // Set the width of .main-content-2-col to 100%
            $('.main-content-2-col').css('width', '100%');
        }

        if ($('#no-result-jobhub').length > 0) {
            // If it exists, hide the .right-sidebar element
            $('.right-sidebar').css('display', 'none');
        }

        // add role none to youtube embed blocks for accessibility
        $(".wp-block-embed-youtube").attr("role", "none");
    });

}(jQuery));