(function($) {
    // Add the "active" class to the proximity dropdown if the URL contains the _proximity parameter
    $(document).ready(function() {
        $('.nav-search-btn-on').on('click', function(e) {
            // Toggle the class on the target element
            if (window.innerWidth > 960) {
                e.preventDefault();
                $('.search-drop-down').toggleClass('disabled');
            }
        });
    });

}(jQuery));