import 'jquery-ui/dist/jquery-ui';

(function($) {
    // Initial setup when the page loads
    $(document).ready(function() {

        // Update Autocomplete Value from Facet
        checkPostcodeVal();

        // Initalise Autocomplete
        $('.autocomplete').autocomplete({
            minLength: 3,
            source: function( request, response ) {
                // Payload
                let data = {
                    'action': 'getpostcodes',
                    term: request.term,
                };

                // Make AJAX request
                $.ajax( {
                    type: 'POST',
                    url: props.ajaxurl,
                    data: data,
                    success: function( data ) {
                        // Pase our response to JSON Object
                        var body = JSON.parse(data);
                        response(body);
                    }
                });
            },
            select: function( event, data ) {
                // Update FacetWP Values
                $('.autocomplete').val(data.item.label);
                updatePostcodeLatLngValues(data.item.lat, data.item.lng);
                updatePostcodeNameValues(data.item.label);
                
                // MARK: We dont need to run this function anymore because
                // we dont use the Google Places API to populate postcodes
                // that we are missing from the database.
                // postNewPostcodeToDatabase(data.item.postcode, data.item.town, data.item.county, data.item.country, data.item.lat, data.item.lng);
                
                if(!$('body').hasClass('home'))
                    FWP.refresh();

                return false;
            }
        });

        // Clear sessionStorage before the page is unloaded
        window.addEventListener('beforeunload', function() {
            sessionStorage.clear(); // Reset all sessionStorage data
        });


        // Retrieve the flag's state from sessionStorage
        var hasReloadedOnce = sessionStorage.getItem('hasReloadedOnce') === 'true';


        // Function to get parameter value by name from URL
        function getParameterByName(name, url) {
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }


        if (window.location.search.indexOf('_proximity=') !== -1 || window.location.search.indexOf('_postcode=') !== -1) {
            //console.log("I have been triggered");
            $('.facetwp-type-proximity').addClass('show');
        }

        /*  
         // Add or remove the "show" class based on URL parameters
         */
        if (window.location.search.indexOf('_proximity=') !== -1) {
            $('.facetwp-facet-proximity.facetwp-type-proximity').addClass('show');
        } else {
            $('.facetwp-facet-proximity.facetwp-type-proximity').removeClass('show');
        }
        if (window.location.search.indexOf('_postcode=') !== -1) {
            $('.facetwp-facet-postcode.facetwp-type-proximity').addClass('show');
        } else {
            $('.facetwp-facet-postcode.facetwp-type-proximity').removeClass('show');
        }

        /*         
        // Listen for facetwp-loaded event after each AJAX refresh

        */
        $(document).on('facetwp-loaded', function() {

            checkPostcodeVal();

            // Find the proximity input element
            var proximityInput = $('.facetwp-facet-proximity .facetwp-location');

            // Retrieve the previous step's URL parameter
            var previousStepParam = getParameterByName('previous_param', window.location.href);

            if (previousStepParam !== '_postcode') {
                sessionStorage.clear(); // Reset sessionStorage if previous step did not have postcode
            }

            // Replace the value attribute with your desired address
            if (proximityInput.length) {
                proximityInput.val('Find jobs near me');
            }

            // Add or remove the "show" class based on URL parameters
            if (window.location.search.indexOf('_proximity=') !== -1) {
                $('.facetwp-facet-proximity.facetwp-type-proximity').addClass('show');
            } else {
                $('.facetwp-facet-proximity.facetwp-type-proximity').removeClass('show');
            }
            if (window.location.search.indexOf('_postcode=') !== -1) {
                $('.facetwp-facet-postcode.facetwp-type-proximity').addClass('show');
            } else {
                $('.facetwp-facet-postcode.facetwp-type-proximity').removeClass('show');
            }

            var currentUrl = window.location.href;
            var proximityParam = getParameterByName('_proximity', currentUrl);
            var postcodeParam = getParameterByName('_postcode', currentUrl);

            // Toggle the hide-proximity class based on postcodeParam
            //var proximityDiv = $('.facetwp-facet-proximity');
            if (proximityParam && postcodeParam) {
                // Remove the _proximity parameter from the URL
                currentUrl = currentUrl.replace(/_proximity=[^&]+&?/, '');
                history.replaceState(null, '', currentUrl);
                $('.facetwp-facet-proximity.facetwp-type-proximity').removeClass('show');
                // Target the element using the provided JS path
                $('li[data-facet="proximity"] span.facetwp-selection-value').removeClass('show');
                // Reload the page
                window.location.reload();
            } else if (postcodeParam) {
                if (!hasReloadedOnce) { // Check if reload has not been triggered before
                    hasReloadedOnce = true; // Set the flag to true after the initial reload
                    sessionStorage.setItem('hasReloadedOnce', 'true'); // Store the flag's state
                    // window.location.reload();
                }
                //FWP.refresh();
                /* if (!hasReloadedOnce) {
                    console.log("--------------------- reloading ---------------------");
                    hasReloadedOnce = true; // Set the flag to true after initial reload
                } */
            }
            // Call your other functions here..


            // Fetch Lat & Lng Values
            var facetPostcodeLatVal = FWP.facets['postcode'][0];
            var facetPostcodeLngVal = FWP.facets['postcode'][1];

            updatePostcodeLatLngValues(facetPostcodeLatVal, facetPostcodeLngVal);
        });



        /*
        // Listen for FacetWP refresh event
        
        */
        document.addEventListener('facetwp-refresh', function() {

            console.log("event refreshed");
            // Find the proximity input element
            var proximityInput = $('.facetwp-facet-proximity .facetwp-location');

            // Replace the value attribute with your desired address
            if (proximityInput.length) {
                proximityInput.val('Find jobs near me');
            }

            // Check URL parameters and apply necessary classes
            if (window.location.search.indexOf('_proximity=') !== -1) {
                $('.facetwp-facet-proximity.facetwp-type-proximity').addClass('show');
            } else {
                $('.facetwp-facet-proximity.facetwp-type-proximity').removeClass('show');
            }

            if (window.location.search.indexOf('_postcode=') !== -1) {
                console.log("postcode in URL found");
            } else {
                console.log("Facet refresh not found");
                $('.facetwp-facet-postcode.facetwp-type-proximity').removeClass('show');
            }


        });

        $(document).on('facetwp-refresh', function() {
            console.log("attmepting to save facet hash");
            if (!FWP.loaded) {
                FWP.temp_set_hash = FWP.set_hash; // on init, save a backup of FWP.set_hash()
            }
            if (FWP.is_reset) {
                console.log("facet reset");
                FWP.set_hash = FWP.temp_set_hash; // on reset, store FWP.set_hash()
            } else {
                FWP.set_hash = function() { /* empty */ } // otherwise, empty it
            }
        });






    });




    jQuery(document).ready(function($) {
        // Find the proximity input element
        var proximityInput = $('.facetwp-facet-proximity .facetwp-location');

        // Replace the value attribute with your desired address
        if (proximityInput.length) {
            proximityInput.val('Find jobs near me');
        }

    });

    $(document).ready(function() {
        /**
         * HTML5 Geo Location API 
         */
        $(document).on('click', '#custom-jlp-location', async function(event) {
            event.stopPropagation();
            event.preventDefault();

            console.log("Clicked the custom JLP location button");

            var currentUrl = window.location.href;
            console.log("Current URL is: " + currentUrl);

            // Remove the _postcode parameter
            currentUrl = currentUrl.replace(/_postcode=[^&]+&?/, '');

            var proximityParam = await getGeoLocationParam();

            if (currentUrl.indexOf('/search-jobs') !== -1) {
                console.log("Window location is on the search-jobs page.");

                // Redirect to job search page with filtered results
                window.location.href = currentUrl + (currentUrl.indexOf('?') !== -1 ? '&' : '?') + '_proximity=' + encodeURIComponent(proximityParam) + '&_sort_=nearest';
            } else {
                var base_url = window.location.origin;
                var searchAndApplyUrl = base_url + '/search-jobs/';

                // Modify the URL based on your requirements
                var modifiedUrl = searchAndApplyUrl + '?_proximity=' + encodeURIComponent(proximityParam) + '&_sort_=nearest';

                // Redirect to the modified URL
                window.location.href = modifiedUrl;
            }
        });



        /**
         * Helper function to get the current geolocation parameter
         */
        function getGeoLocationParam() {
            return new Promise(function(resolve, reject) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function(position) {
                        var geo_lat = position.coords.latitude;
                        var geo_long = position.coords.longitude;
                        var proximity = geo_lat + ',' + geo_long + ',20mi';
                        resolve(proximity);
                    });
                } else {
                    alert("Sorry, your browser does not support HTML5 geolocation.");
                    reject();
                }
            });
        }

    });

    function updatePostcodeLatLngValues(facetPostcodeLatVal, facetPostcodeLngVal)
    {
        // Get all Postcode Facets
        var facetPostcodes = $('.facetwp-facet-postcode');

        // Loop all Postcode Facets
        facetPostcodes.each(function() {
            // Update Postcode Facet Values
            $(this).find('.facetwp-lat').val(facetPostcodeLatVal);
            $(this).find('.facetwp-lng').val(facetPostcodeLngVal);
        });
    }

    function updatePostcodeNameValues(facetPostcodeNameVal)
    {
        // Get all Postcode Facets
        var facetLocations = $('.facetwp-location');

        // Loop all Postcode Facets
        facetLocations.each(function() {
            // Update Postcode Facet Values
            $(this).val(facetPostcodeNameVal);
        });
    }

    function checkPostcodeVal() {
        // Update Autocomplete Value from Facet URL
        let facetPostcodeName = decodeURIComponent(FWP.facets['postcode'][3]);
        if( facetPostcodeName != 'undefined' )
            $('.autocomplete').val(facetPostcodeName);

        // Check if Facet has value
        if(FWP.facets['postcode'].length === 0)
            $('.autocomplete').val("");
    }

    function postNewPostcodeToDatabase(postcode, town, county, country, lat, lng) {
        // Payload
        let data = {
            'action': 'postpostcodes',
            postcode: postcode,
            town: town,
            county: county,
            country: country,
            lat: lat,
            lng: lng,
        };

        // Make AJAX request
        $.ajax( {
            type: 'POST',
            url: props.ajaxurl,
            data: data,
            success: function( data ) {
                console.log('Success');
                // Pase our response to JSON Object
                // var body = JSON.parse(data);
                // response(body);
            }
        });
    }

}(jQuery));