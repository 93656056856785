import Slider from '../sliders/we-saw-you-looking-at';

$(document).ready(function() 
{
	// Set Cookie for testing
	//
	// setCookieConsent("consent:provided|performance:granted|functional:granted|targeting:granted|allcookies:accepted", 7);

	// Check if the production site
	//
	let isProduction = checkForProduction();

	// Check for functional consent on document ready
	//
	let hasFunctionalConsent = ( isProduction ) ? checkForFunctionalConsent() : true;
	handleConsentProvided(hasFunctionalConsent);

	// Load the Latest Opportunities Block
	//
	if ( $('.js-latest-opportunity').length )
		getLatestOpportunities();
	
	// Periodically check for changes to the cookies
	//
	setInterval(checkCookieChanges, 1000);
});

let previousCookies = document.cookie;

/**
 * Function to check for cookie changes
 * 
 * @returns void 
 */
function checkCookieChanges()
{
	const currentCookies = document.cookie;

	if (currentCookies !== previousCookies) 
	{
		previousCookies = currentCookies;

		const isProduction = checkForProduction();
		// const hasFunctionalConsent = ( isProduction ) ? checkForFunctionalConsent() : true;
		const hasFunctionalConsent = checkForFunctionalConsent();
		handleConsentProvided(hasFunctionalConsent);
	}
}

/**
 * Function to handle the consentprovided event
 * 
 * @returns void 
 */
function handleConsentProvided(hasFunctionalConsent) 
{
	// Check if 'functional:true'
	if ( hasFunctionalConsent ) 
	{
		console.log('Functional Cookies are allowed. You can store them!');
		StoreCookie();
	} 
	else 
	{
		console.log('Functional Cookies are not allowed.');
		DeleteCookie();
	}
}

/**
 * Check for functional consent in cookies
 * 
 * @returns boolean 
 */
function checkForFunctionalConsent() 
{
    // Retrieve the CookieConsent cookie value
    let cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith('CookieConsent='))
        ?.split('=')[1];

    // Check if the cookieValue is present and includes 'functional:granted'
    if (cookieValue && cookieValue.includes('functional:granted')) {
        return true;
    }

    return false;
}

/**
 * Get the two currently stored Job IDs from the Cookie
 * 
 * @returns array
 */
function getStoredPageIDs() 
{
	var storedPageIDs = [];

	// Check for the Cookie
	if (document.cookie) 
	{
		// Loop through the Cookies
		var cookies = document.cookie.split(';');
		for (var i = 0; i < cookies.length; i++) 
		{
			// Check if our Viewed Jobs Cookie
			var cookie = cookies[i].trim().split('=');
			if (cookie[0] === 'viewedJobIDs') 
			{
				// Pass our Job IDs into array 
				storedPageIDs = JSON.parse(decodeURIComponent(cookie[1]));
				break;
			}
		}
	}

	return storedPageIDs;
}

/**
 * Set the two most recent Job IDs in the Cookie
 * 
 * @param { int } pageID 
 * 
 * @returns void
 */
function setStoredPageIDs(pageID) 
{
	var storedPageIDs = getStoredPageIDs();
  
	// Check if the pageID is not already in the array
	if (storedPageIDs.indexOf(pageID) === -1) 
	{
		// Add the Job IDs to the array
		storedPageIDs.push(pageID);
	
		// Keep only the last 2 page IDs
		if (storedPageIDs.length > 2) 
		{
			// Remove the oldest page ID
			storedPageIDs.shift();
		}
	
		// Set the Cookie with a 30-day expiry
		var now = new Date();
		now.setTime(now.getTime() + 30 * 24 * 60 * 60 * 1000);
		var expires = 'expires=' + now.toUTCString();
		var cookieValue = encodeURIComponent(JSON.stringify(storedPageIDs));
		document.cookie = 'viewedJobIDs=' + cookieValue + '; path=/; ' + expires;
	}
}

/**
 * Store our cookie if a Job Page
 * 
 * @returns void
 */
function StoreCookie() 
{
	// Check for Job Post Type 
	if ( props.postType === 'jlp_jobs' )
		setStoredPageIDs(props.postID);
}

/**
 * Delete our cookie
 * 
 * @returns void
 */
function DeleteCookie()
{
	document.cookie = 'viewedJobIDs=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

/**
 * Check for Production website
 * 
 * @returns boolean
 */
function checkForProduction() 
{
	var currentHostname = window.location.hostname;
	var targetHostname = 'www.jlpjobs.com';

	return currentHostname === targetHostname ? true : false;
}

/**
 * AJAX Load Latest Opportunity
 * 
 * @returns void
 */
function getLatestOpportunities()
{
	let storedPageIDs = getStoredPageIDs();

	let data = {
		'action': 'latestopportunity',
		'storedPageIDs': storedPageIDs,
	};

	$.ajax({
		url: props.ajaxurl,
		data: data,
		type: 'POST',
		success: function(data) {
			data ? $('.js-latest-opportunity').append(data) : console.log('AJAX Error');

			const slider = new Slider('.js-we-saw-you-looking');
		},
		error: function(jqXHR, textStatus, errorThrown) {
			console.log('AJAX Error:', textStatus, errorThrown);
		}
	});
}

/**
 * Set the CookieConsent cookie for testing purposes
 * 
 * @param {string} value - The value to set for the CookieConsent cookie
 * @param {number} days - The number of days until the cookie expires
 * 
 * @returns void
 */
function setCookieConsent(value, days) 
{
    var expires = "";

    if (days) 
	{
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }

    document.cookie = "CookieConsent=" + (value || "") + expires + "; path=/";
}